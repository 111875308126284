// Import all of Bootstrap's CSS

@import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// Customize some defaults
$body-color: #ffffff;
$body-bg: #fff;
$border-radius: .4rem;
$success: #7952b3;

/*@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

@import "bootstrap/scss/root"; // Required
@import "bootstrap/scss/reboot"; // Required*/
/*@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group"; // Requires forms
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/print";*/


//
// Custom styles
//

@import "icon-list";

@font-face {
  font-family: 'pestifer';
  src: url('../fonts/pestifer.ttf') format("truetype");
}

@font-face {
  font-family: 'germania';
  src: url('../fonts/germania.ttf') format("truetype");
}

@font-face {
  font-family: 'jancient';
  src: url('../fonts/jancient.ttf') format("truetype");
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans.ttf') format("truetype");
}

img {
  max-width: 100%;
}

.customButton {
  width: 200px;
  padding: 10px;
}

body {
  padding: 0;
  font-family: jancient, serif;
  background-image: url(../img/background.webp);
  background-position: center;
  background-attachment: fixed;
  background-size: initial;
  -webkit-background-size: initial;
  color: white;
  font-size: 22px;

  &.dashboardBody {
    padding: 0;
    font-family: 'OpenSans', serif;

    .modalText {
      color: black;
    }
  }

  #main {
    font-family: 'jancient', serif;
    padding-top: 0;

    .flexContent {
      height: calc(100vh - 240px);
      padding-top: 20px;
      display: flex;
      justify-content: center;

      @media (max-width: 991.98px) {
        display: block;
      }

      .mainContent {
        text-align: center;
        margin-top: 30px;
        font-family: 'pestifer', serif;
        font-size: 60px;
        color: #A28F33;
      }
    }

    .mainSocial {
      text-align: center;
      ul {
        padding: 0;
        li {
          display: inline-block;
          margin: 0 30px;
          i {
            color: #A28F33;
          }

          @media (max-width: 991.98px) {
            margin: 0 10px;
          }
        }
      }
    }
  }

  .mainTitle {
    text-align: center;
    font-family: pestifer, serif;
    font-size: 120px;
    color: #A28F33;
    padding: 20px 0;

    @media (max-width: 991.98px) {
      font-size: 70px;
    }
  }

  #latestNews {
    font-family: 'jancient', serif;
    padding-top: 0;
    background-color: rgba(0, 0, 0, 0.6);

    .flexContent {
      height: calc(100vh - 240px);
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-content: start;
      gap: 40px;
      padding-top: 20px;

      @media (max-width: 991.98px) {
        display: block;
        height: auto;
      }

      .mainContent {
        flex: 1;
      }
    }
  }

  #history {
    font-family: 'jancient', serif;
    padding-top: 0;
    background-color: rgba(0, 0, 0, 0.6);

    .historyCopy {
      color: #626262;
    }

    .lineup {
      font-family: 'pestifer', serif;
      font-size: 70px;
      text-align: center;
      color: #A28F33;

      .legend {
        font-family: 'jancient', serif;
        font-size: 13px;
        color: #626262;
        margin-top: -10px;
      }

      .lineupContent {
        font-family: 'jancient', serif;

        .teambox {
          color: #626262;
          margin: 30px 0;
          .bandMate {
            font-size: 22px;
            padding: 0;
          }

          .bandRole {
            font-size: 14px;
            color: #867461;
          }
        }
      }
    }
  }

  #dates {
    font-family: 'jancient', serif;
    padding-top: 0;
    background-color: rgba(0, 0, 0, 0.6);

    .autosize {
      @media (max-width: 991.98px) {
        overflow-x: auto;
      }
      .table {
        tr {
          th {
            color: white;
          }

          td {
            color: #867461;

            a {
              color: #ffffff;
              background: #867461;
              border-color: #867461;
              border-radius: 0;
              padding: 3px 20px;
            }
          }
        }
        @media (max-width: 991.98px) {
          width: 1000px;
        }
      }
    }
  }

  #discography {
    font-family: 'jancient', serif;
    padding-top: 0;
    background-color: rgba(0, 0, 0, 0.6);

    .discographyContent {
      display: flex;
      justify-content: space-between;
      gap: 40px;

      @media (max-width: 991.98px) {
        display: block;
        height: auto;
      }

      .discItem {
        .discItemDescription {
          @media (max-width: 991.98px) {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 10px;
            flex-direction: row-reverse;
            justify-content: space-between;
          }
        }
        @media (max-width: 991.98px) {
          margin-bottom: 20px;
        }

        img {
          width: 280px;
          height: 280px;

          @media (max-width: 991.98px) {
            width: 100%;
            height: auto;
          }
        }

        .discItemYear {
          margin: 5px 0 0 0;
          @media (max-width: 991.98px) {
            font-size: 24px;
          }
        }

        .discItemTitle {
          color: #867461;
          @media (max-width: 991.98px) {
            font-size: 24px;
          }
        }
      }
    }
  }

  #media {
    font-family: 'jancient', serif;
    padding-top: 0;
    background-color: rgba(0, 0, 0, 0.6);

    .mediaContent {
      display: flex;
      justify-content: space-between;
      gap: 0px;
      margin-bottom: 25px;

      @media (max-width: 991.98px) {
        display: block;
      }

      .mediaVideo {
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding: 10px;
        flex: 1;
        margin: 10px;

        iframe {
          width: 100%;
          height: 300px;
        }

        .mediaVideoTitle {
          font-size: 20px;
          color: #867461;
        }

        .mediaVideoLocation {
          font-size: 18px;
          color: #9a9a9a;
        }
      }
    }
  }

  #store {
    font-family: 'jancient', serif;
    padding-top: 0;
    background-color: rgba(0, 0, 0, 0.6);

    .storeContent {
      display: flex;
      justify-content: space-between;
      gap: 0px;
      margin-bottom: 25px;

      @media (max-width: 991.98px) {
        display: block;
      }

      .storeItem {
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding: 10px;
        flex: 1;
        margin: 10px;

        .storeItemImage {
          text-align: center;
          img {
            max-height: 325px;
          }
        }

        .storeItemTitle {
          font-size: 22px;
          color: #867461;
          margin: 15px 0;
        }

        .storeItemLocation {
          font-size: 18px;
          color: #9a9a9a;

          a {
            color: #ffffff;
            background: #867461;
            border-color: #867461;
            border-radius: 0;
            font-size: 19px;
            width: 100%;
            padding: 8px 0;
          }
        }
      }
    }
  }

  #contacts {
    font-family: 'jancient', serif;
    padding-top: 0;
    background-color: rgba(0, 0, 0, 0.6);

    .contactsContainer{
      text-align: center;
      color: #626262;

      img {
        max-width: 100%;
        width: 400px;
      }
    }
  }

  footer {
    padding: 0px;
    margin-bottom: -3rem;
    color: #626262;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .store-logo {
    text-align: center;
  }

  .products {
    text-align: center;
    font-family: 'OpenSans', serif;

    .store-title {
      font-size: 2em;
    }

    .productContainer {}

    .product__legend {
      padding: 15px 0;
      font-size: 24px;
    }

    .product__price{
      font-size: 24px;
    }

    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;

        .product__pic {
          img {
            opacity: 0.8;
          }
        }
      }

      .product {
        text-align: center;

        .product__legend {
          padding: 15px 0;
          font-size: 18px;
        }
      }
    }

    .product {
      text-align: left;
      .product__title {
        font-size: 18px;
        text-decoration: underline;
      }

      .product__price {
        font-size: 18px;
      }

      .product__quantity, .product__username, .product__email, .product__phone, .product__size {
        margin-top: 20px;
        font-size: 16px;
      }

      .product__terms, .product__terms_submit {
        h2 {
          font-size: 14px;
          text-decoration: underline;
        }
        margin: 20px 0;
        font-size: 14px;
      }

      .product__terms {
        .termsContent {
          display: none;
        }
      }

      .product__terms_accept {
        #termsCheck, #termsCheckLabel {
          cursor: pointer;
        }
      }

      .product__terms_submit {
        text-align: right;
      }

      .invalid-feedback {
        font-size: 14px;
      }
    }

    .confirmation__container {
      font-size: 18px;
      padding: 10px;

      b {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 991.98px) {
    background-position-x: 75%;
  }
}

// Style Bootstrap icons
.bi {
  fill: currentcolor;
}

.stickyMenuContainer {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  background: black;
  padding: 0;
  display: none;

  .mobileMenu {
    position: absolute;
    left: 0;
    top: 56px;
    background: black;
    color: white;
    width: 100%;
    display: none;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        a{
          color: white;
          padding: 1rem;
        }
      }
    }
  }
}

#navbarScroll {
  display: flex;
  align-items: start;
  margin-top: 20px;
}

.navbar-expand-lg {
  .navbar-collapse {
    @media (max-width: 991.98px) {
      display: none !important;
    }
  }
}

.navbar {
  background: none !important;

  #mobileMenuButton {
    .navbar-toggler-icon {
      background: none;
      content: initial;
    }

    @media (max-width: 991.98px) {
      border: 0;
      padding: 5px;
      background: none;
      z-index: 1000;
    }
  }

  .small_logo {
    display: inline-block;

    img {
      @media (max-width: 991.98px) {
        max-width: 200px;
        margin-top: 20px;
      }
    }

    @media (max-width: 991.98px) {
      width: 100%;
      text-align: center;
    }
    // max-width: 150px;
  }

  ul.navbar-nav {
    flex: 1;
    justify-content: space-between;

    .nav-item {
      a.nav-link {
        color: #A28F33;
        font-size: 24px;
      }
    }

    &.nav-left {
      .nav-item {
        padding-right: 35px;
      }
    }

    &.nav-right {
      justify-content: space-between;
    }
  }

  .navbar-brand {
    justify-content: center;
    flex: 1;
    display: flex;
    max-width: 200px;
    margin-right: 2rem;
    img {
      max-width: 200px;
      margin-top: -20px;

      &.stickyLogo {
        display: none;
      }

      &.largeLogo {
        display: initial;
      }
    }
  }

  // sticky menu
  &.stickyMenu {
    position: sticky;
    z-index: 100;
    top: 0;
    background: black !important;

    .small_logo {
      display: inline-block;
      max-width: 50px;

      img {
        width: 100%;
        margin-top: 0;
      }
    }

    .navbar-toggler {
      background: white;
    }

    .navbar-brand {
      justify-content: center;
      flex: 1;
      display: flex;
      max-width: 60px;
      img {
        max-width: 60px;
        margin-top: 0px;

        &.largeLogo {
          display: none;
        }

        &.stickyLogo {
          display: initial;
        }
      }
    }

    ul.navbar-nav {
      justify-content: space-between;
      &.nav-left {
        .nav-item {
          a {
            color: white;
            font-size: 22px;
          }
        }
      }

      &.nav-right {
        .nav-item {
          padding-right: 20px;
          &:last-child {
            padding-right: 0;
          }
          a {
            color: white;
            font-size: 22px;
          }
        }
      }
    }
  }
}

section {
  padding: 50px 0;
  h1 {
    text-align: center;
    padding-bottom: 20px;
  }

  .row {
    .main-content {
      text-align: center;
    }
  }

  &#past_editions {
    .row {
      .col-md-6 {
        padding: 15px 0;
      }
    }
  }

  &#bands {
    @media (max-width: 991.98px) {
      border: none;
    }
  }

  @media (max-width: 991.98px) {
    padding-top: 35px;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .products {
    .productContainer {
      border-top: 1px black dashed;
    }

    .store-title {
      padding: 5px 0 20px 0 !important;
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

}

@media print {
  * {
    font-size: 12px !important;
  }
  body {
    background: none;
    font-size: 12px;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust:exact !important;

    .logoutButton, .refreshRecord, .removeRecord {
      display: none;
    }

    .navDash {
      .col-lg-6:last-child {
        display: none;
      }
    }

    .bg-danger, .bg-danger>th, .bg-danger>td  {
      background-color: #dc3545 !important;
    }

    .table-success, .table-success>th, .table-success>td {
      background-color: #c3e6cb !important;
    }

    .table-danger, .table-danger>th, .table-danger>td {
      background-color: #f5c6cb !important;
    }
  }
}
